import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DataStorageService } from '@shared/services/data-storage.service';
//import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService, AuthenticationService, SnackBarService } from '@shared/services';
import esJson from '../../../assets/i18n/es.json';
import enJson from '../../../assets/i18n/en.json';



@Component({
    selector: 'app-associate-form',
    templateUrl: './vouchers-edit.component.html',
    styleUrls: ['./vouchers-edit.component.scss'],
    standalone: false
})


export class VouchersEditComponent implements OnInit {

  langJson = (localStorage.getItem('lang') === 'es') ? esJson : enJson;
  tittle = (localStorage.getItem('lang') === 'es') ? 'Editar bonos' : 'Edit vouchers';
  saveText = (localStorage.getItem('lang') === 'es') ? 'Guardar' : 'Save';
  minDate: Date;
  maxDate: Date;
  selectedTheme: any;
  uploadForm: any;
  imgURL: any;
  selectedColor: any;
  myForm: UntypedFormGroup;
  actualVouchers: any;
  public usesArray = new UntypedFormArray([]);
  public usesArrayNew = new UntypedFormArray([]);
  eventVouchers: any;
  wristbandId: any;
  eventId: any;
  accountId: any;

  constructor(
    public dialogRef: MatDialogRef<VouchersEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data_form: any,
    public dataStorageService: DataStorageService,
    private formBuilder: UntypedFormBuilder,
    private snackbarService: SnackBarService,
    private authenticationService: AuthenticationService,
    ) {
      this.actualVouchers = data_form[0];
      this.wristbandId = data_form[2][0].wristbandId;
      this.accountId = data_form[2][0].accountId;
      this.eventId = data_form[2][0].eventId;
      for (let i = 0; i < this.actualVouchers.length; i++) {
        this.usesArray.push(new UntypedFormControl(''));
      }
      this.eventVouchers = data_form[1]
      for (let i = 0; i < this.eventVouchers.length; i++) {
        this.usesArrayNew.push(new UntypedFormControl(''));
      }
      const currentYear = new Date().getFullYear();
      const currentDate = new Date().toString();
      this.minDate = new Date(currentDate);
      this.maxDate = new Date(currentYear + 20, 11, 31);
      this.myForm = formBuilder.group({
        // email: ['', [Validators.pattern('^[a-zA-Z0-9.!#$%&’*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$'),
        //   Validators.required]]
      });

    }

  ngOnInit(): void {

  }

  onSubmit() {
    this.authenticationService.setLoader(true);
    let editActualVouchersId = [] // Array de Id para los bonos en pulsera editados o no
    let editActualVouchersUses = [] // Array de Usos para los bonos en pulsera editados o no
    for (let i = 0; i < this.usesArray.value.length; i++){
      if (this.usesArray.value[i] === '' || this.usesArray.value[i] === null){
        editActualVouchersUses[i] = parseInt(this.actualVouchers[i].usesLeft)
      } else {
        editActualVouchersUses[i] = this.usesArray.value[i]
      }
      editActualVouchersId[i] = this.actualVouchers[i].id
    }

    let newVoucherToUserId = []
    let newVoucherToUserUses = []
    for (let i = 0; i < this.usesArrayNew.value.length; i++){
      if (this.usesArrayNew.value[i] !== '' && this.usesArrayNew.value[i] !== null && this.usesArrayNew.value[i] === true){
        // newVoucherToUserUses.push(this.usesArrayNew.value[i]) // Por si se quisieran crear con un numero determinado de usos, y no el por defecto
        newVoucherToUserId.push(this.eventVouchers[i].id)
      }
    }

    this.dataStorageService.updateUserUsesLeftDataBase(editActualVouchersUses, editActualVouchersId, this.accountId, this.eventId).subscribe(data => {
      let editResult = data;
      this.dataStorageService.createVouchers(this.wristbandId, newVoucherToUserId, this.accountId, this.eventId).subscribe(data2 => {
        let editResult2 = data2;
        this.authenticationService.setLoader(false);
        this.dialogRef.close(editResult2);
        this.snackbarService.openSnackBar('Bonos editados y añadidos correctamente.');
      },
      err => {
        console.log(err.error)
        this.authenticationService.setLoader(false);
        this.snackbarService.openSnackBar('Bonos editados correctamente pero no nuevos añadidos.');
        this.dialogRef.close(editResult);

      });
    },
    err => {
      this.dataStorageService.createVouchers(this.wristbandId, newVoucherToUserId, this.accountId, this.eventId).subscribe(data2 => {
        let editResult2 = data2;
        this.authenticationService.setLoader(false);
        this.dialogRef.close(editResult2);
        this.snackbarService.openSnackBar('Bonos añadidos correctamente, pero NO editados');
      },
      err => {
        console.log(err.error)
        this.authenticationService.setLoader(false);
        this.snackbarService.openSnackBar(err.error);
        this.dialogRef.close(null);
      });
    });
  }


  onClose(){
    this.dialogRef.close();
  }


}
